<template>
  <div class="family-loan">
    <h1>Cash Box Credit Union Family Loan Plus <span class="small">Borrow up to &pound;2,500 dependant on your savings balance</span></h1>
    <p>
      If you’re an existing member, and you’ve already had a Family Loan with us, then our Family Loan Plus could
      mean that you can borrow more (based on affordability and linked to your savings balance).
    </p>
    <h3>How do I qualify?</h3>
    <p>
      Below are the simple requirements to apply for our credit union Family Loan Plus.
    </p>
    <b-img fluid src="/images/feeding-baby.png" alt="Family"
           class="d-block mx-auto p-0 rounded float-md-right pl-md-3 col-12 col-sm-8 col-md-5 mb-4" />
    <ul>
      <li>Be aged 18 years old or over.</li>
      <li>Have previously had a Family Loan with Cash Box.</li>
      <li>Be in receipt of Child Benefit Payments and agree to have these paid to your Cash Box Credit Union Account.</li>
      <li>The Loan amount is linked to your savings balance so you need to have a minimum of £200 in your Cash Box Savings Account before you apply.</li>
    </ul>
    <p>
      If you meet the above criteria, then our application process is very simple!
    </p>
    <p>
      <a href="https://cashbox.cuaccount.com/login/" target="_blank" class="btn btn-primary mr-2">Existing Members - Apply Here</a>
    </p>
    <p>
      The repayments can be arranged to suit you.
      </p>
    <p>
      You can decide how much of your child benefit is placed into your savings account (subject to a minimum of £2 per week / £10 per month).
    </p>

    <p>What other benefits does the Cash Box Credit Union have?</p>
    <ul>
      <li>Our members are our family</li>
      <li>All applications are looked at by real people, not a computer</li>
      <li>The interest rate is fixed for the term of the loan</li>
      <li>No setup or early repayment fees</li>
      <li>Each payment you make adds a little more to your savings</li>
      <li>
        A loan with your local credit union will help you build or repair your
        credit rating
      </li>
    </ul>

    <p>
      If you need more information on our credit union Family Loan Plus, please contact
      our friendly staff on 0161 342 3266 or by emailing
      <a href="mailto:enquiries@cashbox.org.uk">enquiries@cashbox.org.uk</a>.
    </p>

    <hr />
    <p>
      Credit Unions are financial community co-operatives that promote savings as
      well as loans.
    </p>
    <p>
      Credit Union members pool their savings to provide the funds from which loans
      are made. The interest on loans pays for the running of the credit union and
      if there is a surplus, members get a share of the profit known as a dividend.
    </p>
    <p>
      We expect all of our members to save as much as they can afford, even if they
      are repaying a loan. As a minimum, we would expect you to save at least £2 per
      week/£10 per month and keep this in your savings account while you have a
      loan outstanding with us. Once your savings balance is higher than your loan
      balance, you may if you wish withdraw the difference.
    </p>
  </div>
</template>

<script>
export default {
  name: 'Family Loan',
  metaInfo() {
    return {
      title: '£750 Credit Union Family Loan | Cash Box Credit Union',
      meta: [
        { name: 'keywords', content: "Credit, Union, Family, Loan" },
        { name: 'description', content: "£750 Credit Union Family Loan" }
      ],
    };
  }
}
</script>

<style scoped>

</style>
